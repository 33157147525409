.modal-order {
  .section {
    margin-bottom: $unit*2;
  }
  .desc {
    line-height: 1.333;
    @include fs(-1);
    margin-bottom: $unit;
    text-align: center;
  }


  .forms {
    .form {
      margin-bottom: $unit;
    }
    .goto-form {
      display: block;
      padding: $unit;
      text-decoration: none;
      border-radius: 4px;
      text-align: center;
      span {
        color: white;
        display: block;
        &.text {
          @include fs(-1);
        }
        &.text-main {
          @include fs(2);
          font-weight: bold;
          margin-bottom: $unit/2;
        }
      }

      &.goto-form-taiken {
        background: #006b70;
      }
      &.goto-form-catalog {
        background: #682770;
      }
    }
  }


  .action-tel {
    display: block;
    padding: $unit;
    text-align: center;
    text-decoration: none;
    border: 4px solid #34495e;
    .text-main {
      @include fs(1);
      color: #2c3e50;
      font-weight: bold;
      margin-bottom: $unit/2;
      display: block;

    }
    dl {
      display: inline-flex;
      dt {
        color: #34495e;
        @include fs(-1);
        font-weight: 600;
        margin-right: $unit;
      }
      dd {
        color: #34495e;
        @include fs(-1);
      }
    }
  }

}
