.box-alert {
  padding: 1.6rem;
  background: #fdecea;
  border: 1px solid #ffbbb6;
}

.box-warning {
  padding: 1.6rem;
  background: #fffbd1;
  border: 1px solid #fd3;
}

.box-primary {
  padding: 1.6rem;
  background: #e8f4fd;
  border: 1px solid #a9c2d6;
}

.box-info {
  padding: 1.6rem;
  background: #edf7ed;
  border: 1px solid #9dc49f;
}