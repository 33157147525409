
$colors: (
        main: #16be95,
        main-dark1: #19b088,
        main-dark2: #930d23,
        key1: #00a1ff,
        key2: #fcee21,
        text: #5c5757,
        green: #26a69a,
        bg: #fbb03b
);
/************************************************************************************
 * マーカーの色
 ************************************************************************************/
$marker-yellow: #ff0;
$marker-blue: #69c2f9;
$marker-pink: #ff7bac;
$marker-green: #7bed9f;

/************************************************************************************
 * 基本設定
 ************************************************************************************/
// コンテンツ幅
$width-base: 960px;

// padding、marginの基準
$unit: 1.6rem;

// margin
$margin-base: $unit;
$margin-large: $unit*2;
