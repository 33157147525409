.show-small {
  display: none !important;
}

.show-middle {
  display: none !important;
}

.show-large {
  display: block !important;
}

.hide-small {
  display: block !important;
}

.hide-middle {
  display: block !important;
}

.hide-large {
  display: none !important;
}