.grid {
  width: 100%;
  .row, &.row {
    display: flex;
    //flex-wrap: wrap;
    width: 100%;
    &.row-reverse {
      flex-direction: row-reverse;
    }
    &.middle {
      align-items: center;
    }
  }

  .col {
    &.middle {
      align-self: center;
    }

    &.top {
      align-self: flex-start;
    }

    &.bottom {
      align-self: flex-end;
    }
  }

  .separate, &.separate {
    //margin-left: -1.6rem;

    .col {
      margin-right: 1.6rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .wid_1 { flex-basis: 6.25%; }
  .wid_2 { flex-basis: 12.5%; }
  .wid_3 { flex-basis: 18.75%; }
  .wid_4 { flex-basis: 25%; }
  .wid_5 { flex-basis: 31.25%; }
  .wid_6 { flex-basis: 37.5%; }
  .wid_7 { flex-basis: 43.75%; }
  .wid_8 { flex-basis: 50%; }
  .wid_9 { flex-basis: 56.25%; }
  .wid_10 { flex-basis: 62.5%; }
  .wid_11 { flex-basis: 68.75%; }
  .wid_12 { flex-basis: 75%; }
  .wid_13 { flex-basis: 81.25%; }
  .wid_14 { flex-basis: 87.5%; }
  .wid_15 { flex-basis: 93.75%; }
  .wid_16 { flex-basis: 100%; }

  // 12カラム用
  &.grid-12 {
    .wid_1 { flex-basis: 8.33333333333%; }
    .wid_2 { flex-basis: 16.6666666667%; }
    .wid_3 { flex-basis: 25%; }
    .wid_4 { flex-basis: 33.3333333333%; }
    .wid_5 { flex-basis: 41.6666666667%; }
    .wid_6 { flex-basis: 50%; }
    .wid_7 { flex-basis: 58.3333333333%; }
    .wid_8 { flex-basis: 66.6666666667%; }
    .wid_9 { flex-basis: 75%; }
    .wid_10 { flex-basis: 83.3333333333%; }
    .wid_11 { flex-basis: 91.6666666667%; }
    .wid_12 { flex-basis: 100%; }
  }
}