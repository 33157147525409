/*****************************************************************
 * text-align
 *****************************************************************/
.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}