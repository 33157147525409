/******************************************************************************************
 *
 * ページ共通
 *
 ******************************************************************************************/
.l-contents-pages {
  .page-header {
    text-align: center;
    border-top: 1px solid rgba(white, .1);
    border-bottom: 1px solid rgba(white, .2);
    background: #FEAC5E;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    padding: $unit*6 0;
    margin-bottom: $unit;
    h1 {
      font-family: 'Shippori Mincho B1', serif;
      @include fs(8);
      color: white;
      font-weight: 800;
    }
  }

  .outline-contents {
    background: white;
  }

  .section {
    width: 100%;
    .outline {
      width: 100%;
    }
  }
}

/******************************************************************************************
 *
 * HOME
 *
 ******************************************************************************************/
.page-home {

  .section-main-image {
    .copy-texts {
      background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E);
      padding: $unit;
      text-align: center;
      .text {
        p {
          color: white;
        }
      }
    }
  }

  .outline-contents {
    background: white;

  }

  .section-lesson-style {
    padding: $unit;
    h1 {
      @include fs(3);
      text-align: center;
      margin-bottom: $unit*2;
    }
  }

  .section-faq {
    padding: $unit;
    h1 {
      background: #65959d;
      @include fs(4);
      text-align: center;
      font-weight: 800;
      color: white;
      padding: $unit;
      margin-bottom: $unit*2;
    }

    .qa {
      margin-bottom: $unit*2;
      .q {
        display: inline-flex;
        background: #bd8032;
        width: 100%;
        align-items: center;
        margin-bottom: $unit/2;
        padding: 2px;
        .icon {
          background: #bd8032;
          padding: 0 $unit;
          @include fs(2);
          color: white;
        }
        p {
          background: white;
          padding: $unit;
          width: 100%;
        }
      }

      .a {
        display: inline-flex;
        width: 100%;
        align-items: center;
        background: #1b1464;
        padding: 2px;
        .icon {
          color: white;
          @include fs(2);
          padding: $unit;
        }
        p {
          background: white;
          width: 100%;
          padding: $unit;
          line-height: 1.3333;
        }
      }
    }

    .point {
      @include fs(2);
      color: #387680;
      line-height: 1.66666;
    }
  }
}

/******************************************************************************************
 * しち塾について
 ******************************************************************************************/
.page-about {
  .outline-contents {
  }
  .section-about {
    padding: $unit;
    font-family: 'Shippori Mincho B1', serif;
    font-weight: 800;

    .shin {
      font-weight: 400;
    }

    p {
      @include fs(1);
      line-height: 1.6666;
      margin-bottom: $unit;
    }

    .sichi {
      padding: $unit*4 $unit;
      h1 {
        text-align: center;
        @include fs(4);
        margin-bottom: $unit*2;
      }

      dl {
        width: 100%;
        margin-bottom: $unit;
        dt, dd {
          @include fs(1);
        }
        dt {
          padding-right: $unit;
          font-weight: 800;
          @include fs(2);
        }
      }
    }
  }
}
/******************************************************************************************
 * プラン
 ******************************************************************************************/

.page-plan {

  .section-plan {
    padding: $unit*2 $unit;

    table {
      width: 100%;
      th, td {
        padding: $unit $unit/2;
        line-height: 1.333;
      }

      thead {
        background: #82589F;
        th {
          font-weight: 800;
          text-align: left;
          color: white;
          @include fs(-1);
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #ddd;

          th {
            text-align: left;
            dl {
              margin-bottom: 0;
              dt {
                font-weight: bold;
                color: #82589F;
                margin-bottom: $unit/2;
                @include fs(-1);
              }
              dd {
                line-height: 1.3333;
                @include fs(-1);
              }
            }
          }

          td {
            ul {
              li {
                margin-bottom: $unit/2;
                @include fs(-1);
              }
            }
          }
        }

      }
    }
  }
}
/******************************************************************************************
 * よくあるご質問
 ******************************************************************************************/
.page-faq {
  .section-faq {
    padding: $unit;

    .qa {
      margin-bottom: $unit*2;
      .q {
        display: inline-flex;
        background: #bd8032;
        width: 100%;
        align-items: center;
        margin-bottom: $unit/2;
        padding: 2px;
        .icon {
          background: #bd8032;
          padding: 0 $unit;
          @include fs(2);
          color: white;
        }
        p {
          background: white;
          padding: $unit;
          width: 100%;
        }
      }

      .a {
        display: inline-flex;
        width: 100%;
        align-items: center;
        background: #1b1464;
        padding: 2px;
        .icon {
          color: white;
          @include fs(2);
          padding: $unit;
        }
        p {
          background: white;
          width: 100%;
          padding: $unit;
          line-height: 1.3333;
        }
      }
    }

    .point {
      @include fs();
      font-weight: 800;
      color: #387680;
      line-height: 1.66666;
    }
  }

}
/******************************************************************************************
 * クリニック検索
 ******************************************************************************************/
.page-place {

  .place {
    padding: $unit*4 0;
    text-align: center;
    h1 {
      @include fs(5);
      font-weight: 800;
      margin-bottom: $unit;
    }

    .map {
      margin-top: $unit*2;
    }
  }

}

/******************************************************************************************
 * 会社概要
 ******************************************************************************************/
.page-corporate {
  .section-corporate {
    padding: $unit;

    .data {
      width: 100%;
      tr {
        border-bottom: 1px dotted rgba(black, .2);
      }
      th, td {
        padding: $unit;
        text-align: left;
      }
      th {
        white-space: nowrap;
      }
      td {
        width: 100%;
      }
    }
  }

}
