@mixin fs($size:0) {
  $fs: map-get($font-size, base);

  @if $size == -2 {
    $fs: map-get($font-size, s-2);
  }

  @if $size == -1 {
    $fs: map-get($font-size, s-1);
  }

  @if $size == 1 {
    $fs: map-get($font-size, x-1);
  }

  @if $size == 2 {
    $fs: map-get($font-size, x-2);
  }

  @if $size == 3 {
    $fs: map-get($font-size, x-3);
  }

  @if $size == 4 {
    $fs: map-get($font-size, x-4);
  }

  @if $size == 5 {
    $fs: map-get($font-size, x-5);
  }

  @if $size == 6 {
    $fs: map-get($font-size, x-6);
  }

  @if $size == 7 {
    $fs: map-get($font-size, x-7);
  }

  @if $size == 8 {
    $fs: map-get($font-size, x-8);
  }

  @if $size == 9 {
    $fs: map-get($font-size, x-9);
  }
  font-size: $fs;
}