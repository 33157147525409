/******************************************************************************************
 *
 * レイアウト
 *
 ******************************************************************************************/
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'FP-HiraKakuProN-W3', 'Meiryo', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'Yu Gothic', sans-serif;
  padding-top: 62px;
}

body {
  font-size: map-get($font-size, base);
  color: #333;
}

img {
  vertical-align: bottom;
  &.size-auto {
    width: 100%;
  }
}


/*************************************************************************************
 *
 * ヘッダ
 *
 *************************************************************************************/
.l-header {
  display: none;
}


.l-header-small {
  width: 100%;
  border-bottom: 4px solid #006b70;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  .items {
    display: flex;
    align-items: center;
    .brand {
      flex-basis: 40%;
      img {
        width: 100%;
      }
    }

    .actions {
      flex-basis: 60%;
      text-align: right;
      .action {
        display: inline-block;
        padding: $unit/2 $unit/2;
        border-radius: 2px;
        @include fs(-1);
        text-align: center;
        border-left: 1px solid #ddd;
        text-decoration: none;
        color: #666;
        .icon {
          display: block;
          margin-bottom: 8px;
          i {
            @include fs(4);
          }
        }
        .text {
          display: block;
          font-size: 0.9rem;
        }
      }
    }
  }
}
/*************************************************************************************
 * ドロワーナビゲーション
 *************************************************************************************/
.drawer-menu {
  display: none;
  background: white;

  .brand {
    display: block;
    padding: $unit*2 $unit;
    img {
      width: 100%;
    }
  }
  .drawer-section {
    > header {
      background: whitesmoke;
      padding: 4px $unit;
      border-bottom: 2px solid rgba(0,0,0,.1);
      .section-title {
        @include fs(-1);
        padding-right: 8px;
        display: inline;
      }
    }

    .section-body {
      a {
        color: black;
        display: block;
        text-decoration: none;
        padding: $unit;
        border-top: 1px solid rgba(white, .1);
        border-bottom: 1px solid rgba(black, .1);
      }
    }
  }

  .pages {

  }
}


/******************************************************************************************
 * インフォメーションバー
 ******************************************************************************************/
.info-bar {
  padding: $unit;
  background: #65959d;
  .outline {
    margin: 0 auto;
  }
  text-align: center;
  p {
    text-align: center;
    color: white;
    .label-new {
      display: inline-block;
      padding: $unit/3 $unit;
      background: red;
      @include fs(-1);
    }

    .important {
      font-weight: 800;
      color: yellow;
    }

    .text {
      display: block;
      margin-top: $unit;
    }

  }
}
/******************************************************************************************
 *
 * レイアウト
 *
 ******************************************************************************************/
.contents {
  padding: 0 0 $unit*2 0;
}



/******************************************************************************************
 * .com 各ページへのリンク
 ******************************************************************************************/
.nav-group-pages {
  $_bg: #273c75;
  $_color_link: white;

  width: 100%;
  margin: 0 auto;
  background: $_bg;
  a {
    color: $_color_link;
    text-decoration: none;
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  .nav-record {
    .col {
    }
  }

  /*----------------------------------------
   * タイトル
   *----------------------------------------*/
  .site-titles {

    color: #fff;
    display: block;
    position: relative;
    background: darken($_bg, 8%);
    font-weight: bold;

    .site-title {
      display: inline-block;
      padding: 8px 8px;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      padding: 8px 16px;
      background: lighten($_bg, 8%);
    }

  }
  /*----------------------------------------
   * ページリンク
   *----------------------------------------*/
  ul.site-links {
    padding: 16px 8px;
    font-size: map-get($font-size, small);
    a {
      display: block;
      padding: 2px;
      &:before {
        content: "> ";

      }
      &:hover {
        background: lighten($_bg, 4%);
        text-decoration: none;
      }
    }
  }
}

/******************************************************************************************
 * フッタ
 ******************************************************************************************/
.l-footer {
  width: 100%;
  $_bg: #303952;
  background: $_bg;
  color: white;

  .contents {
    .content {
    }
    .content-brand {
      text-align: center;
      padding: $unit*4 $unit*2;
      h1 {
        margin-top: $unit;
        @include fs(-1);
      }
    }
    .content-pages {
      h1 {
        @include fs(2);
        @include ff(400);
        margin-bottom: $unit;
        text-align: center;
        background: darken($_bg, 8%);
        padding: $unit;
      }
      ul {
        padding: $unit $unit*4;
        li {
          a {
            color: white;
            text-decoration: none;
            display: block;
            padding: $unit/2 0;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

    }
  }

  .copyright {
    @include fs(-1);
    @include ff(100);
    padding: $unit;
    color: white;
    text-align: center;
    background: darken($_bg, 4%);
  }
}
