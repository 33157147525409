.marker {
  @include marker($marker-yellow);
}

.marker-yellow {
  @include marker($marker-yellow);
}

.marker-pink {
  @include marker($marker-pink);
}

.marker-blue {
  @include marker($marker-blue);
}

.marker-green {
  @include marker($marker-green);
}