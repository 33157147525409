/**********************************************
 * 垂直水平方向のpadding
 **********************************************/
.pd-1 {
  padding: $unit;
}

.pd-2 {
  padding: $unit*2;
}

.pd-3 {
  padding: $unit*2;
}

.pd-4 {
  padding: $unit*2;
}

.pd-5 {
  padding: $unit*2;
}
/**********************************************
 * 垂直方向のpadding
 **********************************************/
.pdv-1 {
  padding-top: $unit;
  padding-bottom: $unit;
}
.pdv-2 {
  padding-top: $unit*2;
  padding-bottom: $unit*2;
}
.pdv-3 {
  padding-top: $unit*3;
  padding-bottom: $unit*3;
}
.pdv-4 {
  padding-top: $unit*4;
  padding-bottom: $unit*4;
}
.pdv-5 {
  padding-top: $unit*5;
  padding-bottom: $unit*5;
}
/**********************************************
 * 水平方向のpadding
 **********************************************/
.pdh-1 {
  padding-right: $unit;
  padding-left: $unit;
}
.pdh-2 {
  padding-right: $unit*2;
  padding-left: $unit*2;
}
.pdv-3 {
  padding-right: $unit*3;
  padding-left: $unit*3;
}
.pdh-4 {
  padding-right: $unit*4;
  padding-left: $unit*4;
}
.pdh-5 {
  padding-right: $unit*5;
  padding-left: $unit*5;
}