@import "vars";
/******************************************************************************************
 * ライブラリ
 ******************************************************************************************/
// JDライブラリ設定
@import "libs/reset";
@import "libs/text-align";

@import "libs/jd/vars";
@import "libs/jd/mixins";
@import "libs/jd/tab";
//@import "libs/responsive/youtube";

/*-------------------------------------------------------------
 * Mixins
 *------------------------------------------------------------*/
@import "mixins/font-family";

/*-------------------------------------------------------------
 * Font Awesome
 *------------------------------------------------------------*/
$fa-font-path: "./webfonts";
//@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
/*-------------------------------------------------------------
 * モーダル
 *------------------------------------------------------------*/
@import "../node_modules/tingle.js/dist/tingle.min";



// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__bullet {
  background-color: rgba(black, .3);
  &.glide__bullet--active {
    background-color: rgba(black, .9);
  }
}


/******************************************************************************************
 * アプリケーション
 ******************************************************************************************/
/*-------------------------------------------------------------
 * モバイル
 *------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  @import "app.small/vars";

  // ライブラリ ---------------------------------------------------
  @import "libs/jd/vars";
  @import "libs/jd/mixins";
  @import "mixins/font-size";
  @import "mixins/marker";

  @import "libs/scale";
  @import "libs/alerts";
  @import "libs/marker";
  @import "libs/margin";
  @import "libs/responsive/show-hide.small";

  // コンテンツ ---------------------------------------------------
  @import "app.small/layout";
  @import "app.small/contents";
  @import "app.small/modal-order";
}

/*-------------------------------------------------------------
 * PC
 *------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  @import "app.large/vars";

  // ライブラリ ---------------------------------------------------
  @import "libs/jd/vars";
  @import "libs/jd/mixins";
  @import "mixins/marker";

  @import "libs/grid";
  @import "libs/margin";
  @import "libs/padding";
  @import "libs/text-align";
  @import "libs/scale";
  @import "libs/alerts";
  @import "libs/marker";
  @import "libs/responsive/show-hide.large";

  @import "mixins/font-size";


  // コンテンツ ---------------------------------------------------
  @import "app.large/layout";
  @import "app.large/contents";
}
