
/************************************************************************************
 * 基本設定
 ************************************************************************************/
// padding、marginの基準
$unit: 1.6rem;

/************************************************************************************
 * フォントサイズ
 ************************************************************************************/
$font-size: (
  x-9: 3.4rem,
  x-8: 3.2rem,
  x-7: 3.0rem,
  x-6: 2.8rem,
  x-5: 2.6rem,
  x-4: 2.4rem,
  x-3: 2.2rem,
  x-2: 2.0rem,
  x-1: 1.8rem,
  base: 1.6rem,
  s-1: 1.4rem,
  s-2: 1.2rem,
  s-3: 1.0rem
);


$color-sansu: #6391c4;
$color-kokugo: #d07c94;
$color-rika: #e8a260;
$color-shakai: #46ac7b;