/**********************************************
 * 垂直水平方向のmargin
 **********************************************/
.mg-1 {
  margin: $unit;
}

.mg-2 {
  margin: $unit*2;
}

.mg-3 {
  margin: $unit*3;
}

.mg-4 {
  margin: $unit*4;
}

.mg-5 {
  margin: $unit*5;
}


/**********************************************
 * margin-bottom
 **********************************************/
.mgb-1 {
  margin-bottom: $unit;
}

.mgb-2 {
  margin-bottom: $unit*2;
}

.mgb-3 {
  margin-bottom: $unit*3;
}

.mgb-4 {
  margin-bottom: $unit*4;
}

.mgb-5 {
  margin-bottom: $unit*5;
}