
/******************************************************************************************
 *
 * ページ共通
 *
 ******************************************************************************************/
.l-contents-pages {
  .page-header {
    text-align: center;
    border-top: 1px solid rgba(white, .1);
    border-bottom: 1px solid rgba(white, .2);

    margin-bottom: $unit;
    background: #e5a840;
    display: flex;
    .outline {
      flex-basis: 1100px;
      margin: 0 auto;
      position: relative;
      padding: $unit*6 0;
      h1 {
        @include ff(800);
        @include fs(8);
        color: #000;
        font-weight: 800;
      }

      .hand {
        position: absolute;
        left: 0;
        bottom: -159px;
        z-index: 1;
      }
    }


  }

  .outline-contents {
    background: white;
    width: 960px;
    margin: 0 auto $unit*4 auto;
  }

  .section {
    width: 100%;

    .outline {
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      &.outline-small {
        max-width: 990px;
      }
    }

    h1, h2, h3, h4, h5, h6,
    p, li, dt, dd, th, td {
      //font-family: 'Noto Sans JP', sans-serif;
      @include ff(400);
    }
  }

  .separate-large {
    height: 32px;
    background: url("/assets/shared/point.square.large.png") no-repeat center center;
  }

  .separate-small {
    height: 32px;
    background: url("/assets/shared/point.square.png") no-repeat center center;
  }
}

/******************************************************************************************
 *
 * HOME
 *
 ******************************************************************************************/
.page-home {

  .section-main-image {
    //background: #e5a840;
    margin-bottom: 50px;
    .outline {
      position: relative;
      .hand {
        position: absolute;
        bottom: -157px;
        z-index: 1;
      }
    }

  }

  .outline-contents {
    background: white;
    width: 1100px;
    margin: 0 auto;
  }

  .section-lesson-style {
    padding: $unit*4;
    text-align: center;

    h1 {
      text-align: center;

      .text {
        display: inline-block;
        @include fs(6);
        margin-bottom: $unit*2;
        position: relative;

        &::before {
          position: absolute;
          content: url("/assets/shared/quo.left.png");
          display: block;
          width: 45px;
          height: 45px;
          left: -50px;
          top: -8px;
        }
        &::after {
          position: absolute;
          content: url("/assets/shared/quo.right.png");
          display: block;
          width: 45px;
          height: 45px;
          right: -50px;
          top: -8px;
        }
      }

    }


    h2 {
      @include fs(2);
    }

  }

  .section-courses {
    padding: $unit*4 0;
    .courses {
      display: flex;

      .course {
        flex-basis: 33.33333%;
        padding: 0 $unit*2;

        background: url("/assets/home/course.separate.png") no-repeat right center;

        &:last-child {
          background: none;
        }
        h1 {
          text-align: center;
          border-bottom: 2px solid #d70046;
          padding: $unit 0;
          @include fs(2);
          font-weight: 800;
        }

        ul {
          padding: $unit $unit*2;

          li {
            margin-bottom: $unit/2;
          }
        }
      }
    }
  }


  .section-match {
    padding: $unit*4 0;

    h1 {
      text-align: center;

      .text {
        display: inline-block;
        @include fs(6);
        margin-bottom: $unit*2;
        position: relative;

        &::before {
          position: absolute;
          content: url("/assets/shared/quo.left.png");
          display: block;
          width: 45px;
          height: 45px;
          left: -50px;
          top: -8px;
        }
        &::after {
          position: absolute;
          content: url("/assets/shared/quo.right.png");
          display: block;
          width: 45px;
          height: 45px;
          right: -50px;
          top: -8px;
        }
      }

    }

    .pic-and-text {
      display: flex;
      width: 990px;
      margin: 0 auto;

      .list {
        ul {
          li {
            padding: $unit $unit $unit $unit*3;
            background: url("/assets/shared/point.check.png") no-repeat left center;
            font-weight: 800;
            @include fs(1);
          }
        }
      }
    }
  }


}

/******************************************************************************************
 * しち塾について
 ******************************************************************************************/
.page-about {
  .outline-contents {
    width: 800px;
  }

  .section-about {
    padding: $unit*4;
    font-family: 'Shippori Mincho B1', serif;
    font-weight: 800;

    .shin {
      font-weight: 400;
    }

    p {
      @include fs(1);
      line-height: 1.6666;
      margin-bottom: $unit;
    }

    .sichi {
      padding: $unit*4;

      h1 {
        text-align: center;
        @include fs(6);
        margin-bottom: $unit*2;
      }

      dl {
        display: inline-flex;
        width: 100%;
        margin-bottom: $unit/2;

        dt, dd {
          @include fs(1);
        }

        dt {
          padding-right: $unit;
          font-weight: 800;
        }
      }
    }
  }
}

/******************************************************************************************
 * プラン
 ******************************************************************************************/

.page-plan {

  .section-plan {
    padding: $unit*2;

    table {
      width: 100%;

      th, td {
        padding: $unit;
        line-height: 1.333;
        @include ff(400);
      }

      thead {
        background: #12bfcf;

        th {
          font-weight: 800;
          text-align: left;
          color: white;
          @include ff(800);
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #ddd;

          th {
            text-align: left;

            dl {
              margin-bottom: 0;

              dt {
                font-weight: bold;
                @include fs(1);
                color: #12bfcf;
                margin-bottom: $unit/2;
              }

              dd {
                line-height: 1.3333;
              }
            }
          }

          td {
            ul {
              li {
                margin-bottom: $unit/2;
              }
            }
          }
        }

      }
    }
  }
}

/******************************************************************************************
 * よくあるご質問
 ******************************************************************************************/
.page-faq {


  .section-faq {
    padding: $unit*4;

    .qa {
      margin-bottom: $unit*2;

      .q {
        display: inline-flex;
        background: #bd8032;
        width: 100%;
        align-items: center;
        margin-bottom: $unit/2;
        padding: 2px;

        .icon {
          background: #bd8032;
          padding: 0 $unit;
          @include fs(2);
          color: white;
        }

        p {
          background: white;
          padding: $unit;
          width: 100%;
        }
      }

      .a {
        display: inline-flex;
        width: 100%;
        align-items: center;
        background: #1b1464;
        padding: 2px;

        .icon {
          color: white;
          @include fs(2);
          padding: $unit;
        }

        p {
          background: white;
          width: 100%;
          padding: $unit;
          line-height: 1.3333;
        }
      }
    }

    .point {
      text-align: center;
      @include fs(3);
      color: #387680;
      line-height: 1.66666;
    }
  }

}

/******************************************************************************************
 * クリニック検索
 ******************************************************************************************/
.page-place {

  .place {
    padding: $unit*4 0;
    text-align: center;

    h1 {
      @include fs(5);
      font-weight: 800;
      margin-bottom: $unit;
    }

    .map {
      margin-top: $unit*2;
    }
  }

}

/******************************************************************************************
 * 会社概要
 ******************************************************************************************/
.page-corporate {
  .section-corporate {
    padding: $unit*4;

    .data {
      width: 100%;

      tr {
        border-bottom: 1px dotted rgba(black, .2);
      }

      th, td {
        padding: $unit;
        text-align: left;
      }

      th {
        white-space: nowrap;
      }

      td {
        width: 100%;
      }
    }
  }

}
