#{$ns}-nav-tab {
  .tab-header {
    .nav {
      display: inline-block;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
}




