/******************************************************************************************
 *
 * 全体設定
 *
 ******************************************************************************************/
html {
  font-size: 62.5%;
  @include ff(400);
  //font-family: 'Meiryo', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'Yu Gothic', sans-serif;
  background: #fff;
}

body {
  color: #333;
  padding-top: 226px;
}

img {
  vertical-align: bottom;

  &.size-auto {
    width: 100%;
  }
}

/******************************************************************************************
 * 全体の枠
 ******************************************************************************************/
.app {
  width: 100%;
}
.drawer-menu {
  display: none;
}
/******************************************************************************************
 * ヘッダ
 ******************************************************************************************/
.l-header-small {
  display: none;
}


.l-header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: $unit 0 0 0;
  background: white;
  box-shadow: 0 9px 8px -8px rgba(0, 0, 0, 0.4);
  transition: all 1s;

  .brand-and-actions {
    width: 990px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    h1 {
      width: 373px;

      img {
        width: 100%;
      }
    }

    .actions {
      padding: $unit/2 0;
      width: 720px;
      text-align: right;

      .taiken-and-catalog {

        .order-by-taiken, .order-by-catalog {
          display: inline-flex;
          border-radius: 2px;
          align-items: center;
          text-decoration: none;
          color: black;

          .icon {
          }

          .text {
            text-align: left;

            .line {
              display: block;
              @include fs(-1);
              font-weight: 600;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .order-by-taiken {
        }

        .order-by-catalog {
        }
      }

      .call {
        display: inline-block;
      }
    }
  }

  &.active {
    visibility: visible;
    transition: all .6s;
    transform: scaleY(1);
    transform-origin: 0 0;
    transition-timing-function: ease-in-out;
  }

  &.disabled {
    visibility: hidden;
    opacity: 0;
    transition: all .6s;
    transform-origin: 0 0;
    transform: scaleY(0) translateY(-100px);
    animation-timing-function: ease-in-out;
  }

  #js-sidebar-trigger {
    display: none;
  }


  /******************************************************************************************
   * グローバルナビゲーション
   ******************************************************************************************/
  .nav-global {
    background-image: linear-gradient(#fff 50%, #444 50%);
    padding: $unit*1.5 0;
    .navs {
      width: 990px;
      margin: 0 auto;
      display: flex;
      background: white;
      box-shadow: 0 0 8px rgba(black, .1);
      border-radius: 8px;
      a {
        @include fs(-1);
        text-align: center;
        color: black;
        padding: $unit*1.5 $unit/2;
        display: block;
        text-decoration: none;
        flex-basis: 100%;
        @include ff(500);
        background: url("/assets/shared/nav.global.separate.png") no-repeat right center;
        &:last-child {
          background: none  ;
        }
        &.active {
        }
      }
    }
  }
}


/******************************************************************************************
 * コンテンツ
 ******************************************************************************************/
main {
  display: block; //IE 11 用
  width: 100%;
}


/******************************************************************************************
 * フッタ
 ******************************************************************************************/
.l-footer {
  width: 100%;
  $_bg: #444444;
  color: white;
  background: $_bg url("/assets/shared/footer.bg.png") no-repeat right 0;
  padding: $unit*1.5 0;
  .contents {
    display: flex;
    width: 990px;
    margin: 0 auto;
    position: relative;

    .goto-top {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .content {
    }

    .content-brand-and-nav {
      flex-basis: 244px;

      .logo {
        margin-bottom: $unit*2;
      }
      .nav-footer {
        padding-left: $unit;
        ul {
          border-left: 1px solid white;
          margin-bottom: 0;
          li {
            padding-left: $unit;

            &:first-child {
              a {
                padding-top: 0;
              }
            }

            &:last-child {
              a {
                padding-bottom: 0;
              }
            }

            a {
              text-decoration: none;
              color: white;
              display: inline-block;
              padding: $unit/2 0;
              @include fs(-2);

              &:first-child {
              }

              &:last-child {
              }
            }
          }
        }

      }
    }


    .content-actions {
      flex-basis: 100%;
      .taiken-and-catalog {
        text-align: right   ;
        flex-basis: 990 - 244px;
        .order-by-taiken, .order-by-catalog {
          display: inline-flex;
          border-radius: 2px;
          align-items: center;
          text-decoration: none;
          color: white;

          .icon {
          }

          .text {
            text-align: left;

            .line {
              display: block;
              @include fs(-1);

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .order-by-taiken {
        }

        .order-by-catalog {
        }
      }
    }

  }

  .copyright {
    padding-top: $unit*2;
    width: 990px;
    color: white;
    margin: 0 auto;
    @include fs(-2);
  }
}



